import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../stores/api';
import errorHandler from '../stores/errorHandler';

export const formSubmit = createAsyncThunk(
  'formSubmit/form-submit',
  async (userInput, { rejectWithValue }) => {
    try {
      const response = await api.post('pdf/pdf-response', userInput);
      if (response?.data?.status_code === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      let errors = errorHandler(err);
      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  message: null,
  error: null,
  isLoading: false,
  response: null,

}

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(formSubmit.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(formSubmit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = false;
        state.message = payload !== undefined && payload.message ? payload.message : 'Something went wrong. Try again later.';
      })
      .addCase(formSubmit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = true;
        state.message = payload !== undefined && payload.message ? payload.message : 'Something went wrong. Try again later.';
      })
  }
});


export default slice.reducer;