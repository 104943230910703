import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question1 from '../components/question1'
import './home.css'
import './personalized-fitness-plan.css'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { formSubmit } from '../reducers/Slice'
import Loader from '../loader/loader'

const PersonalizedFitnessPlan = () => {

  const dispatch = useDispatch();
  const { message, error, isLoading } = useSelector((state) => state.form);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    dispatch(formSubmit(data));
  }

  useEffect(() => {
    if (error && message) {
      setErrorMessage(message);
    } else if (!error && message) {
      setSuccessMessage(message);
    }
  }, [message, error]);


  return (
    <div className="home-container">
      <Helmet>
        <title>Spotless Hungry Crocodile</title>
      </Helmet>
      <div className="home-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer home-navbar-interactive"
        >
          <span className="logo">FITEMPOWER</span>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <span className="home-nav12 bodySmall">Home</span>
              <span className="home-nav2 bodySmall">About</span>
              <span className="home-nav3 bodySmall">Services</span>
              <span className="home-nav4 bodySmall">Testimonials</span>
              <span className="home-nav5 bodySmall">Contact</span>
            </nav>
            <div className="home-buttons">
              <button className="home-login buttonFlat">Login</button>
              <button className="buttonFilled">Register</button>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <span className="logo">FITEMPOWER</span>
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span>Home</span>
                <span className="home-nav21 bodySmall">About</span>
                <span className="home-nav31 bodySmall">Services</span>
                <span className="home-nav41 bodySmall">Testimonials</span>
                <span className="home-nav51 bodySmall">Contact</span>
              </nav>
              <div className="home-buttons1">
                <button className="buttonFlat">Login</button>
                <button className="buttonFilled">Register</button>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="heroContainer home-hero1">
          <div className="home-container01">
            <h1 className="home-hero-heading heading1">Personalized Fitness Plan</h1>
            <div className='personalized_fitness_plan_area'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>What is your current fitness goal? </label>
                  <div className='radio_box'>
                    <input type="radio" id="LooseWeight" name="fitness_goal"
                      value="LooseWeight"
                      defaultChecked
                      {...register('fitness_goal')} />
                    <label for="LooseWeight">Loose Weight</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="BuildMuscleMass" name="fitness_goal"
                      value="BuildMuscleMass"
                      {...register('fitness_goal')} />
                    <label for="BuildMuscleMass">Build Muscle Mass</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="cardiovascularhealth" name="fitness_goal"
                      value="cardiovascularhealth"
                      {...register('fitness_goal')} />
                    <label for="cardiovascularhealth">Improve endurance/cardiovascular health</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Increaseflexibility" name="fitness_goal"
                      value="Increaseflexibility"
                      {...register('fitness_goal')} />
                    <label for="Increaseflexibility">Increase flexibility</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="improvedhealth" name="fitness_goal"
                      value="improvedhealth"
                      {...register('fitness_goal')} />
                    <label for="improvedhealth">General fitness/improved health</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="specifictraining" name="fitness_goal"
                      value="specifictraining"
                      {...register('fitness_goal')} />
                    <label for="specifictraining">Sport-specific training</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>What fitness equipment do you have at home? </label>
                  <input type="text" placeholder='Your answer'
                    name="fitness_equipments"
                    {...register('fitness_equipments')} />
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>What is your zip code? </label>
                  <input type="text" placeholder='Your answer'
                    name="zip_code"
                    {...register('zip_code')} />
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Current Fitness Level </label>
                  <div className='radio_box'>
                    <input type="radio" id="Beginner" name="fitness_level"
                      value="Beginner"
                      defaultChecked
                      {...register('fitness_level')} />
                    <label for="Beginner">Beginner</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Intermediate" name="fitness_level"
                      value="Intermediate"
                      {...register('fitness_level')} />
                    <label for="Intermediate">Intermediate</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Advanced" name="fitness_level"
                      value="Advanced"
                      {...register('fitness_level')} />
                    <label for="Advanced">Advanced</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>How many times per week do you want to workout?  </label>
                  <div className='radio_box'>
                    <input type="radio" id="workout1" name="workout_perweek"
                      value="workout1"
                      defaultChecked
                      {...register('workout_perweek')} />
                    <label for="workout1">1</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout2" name="workout_perweek"
                      value="workout2"
                      {...register('workout_perweek')} />
                    <label for="workout2">2</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout3" name="workout_perweek"
                      value="workout3"
                      {...register('workout_perweek')} />
                    <label for="workout3">3</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout4" name="workout_perweek"
                      value="workout4"
                      {...register('workout_perweek')} />
                    <label for="workout4">4</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout5" name="workout_perweek"
                      value="workout5"
                      {...register('workout_perweek')} />
                    <label for="workout5">5</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout6" name="workout_perweek"
                      value="workout6"
                      {...register('workout_perweek')} />
                    <label for="workout6">6</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="workout7" name="workout_perweek"
                      value="workout7"
                      {...register('workout_perweek')} />
                    <label for="workout7">7</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Exercise Preferences </label>
                  <div className='radio_box'>
                    <input type="radio" id="Cardio" name="exercise_preferences"
                      value="Cardio"
                      defaultChecked
                      {...register('exercise_preferences')} />
                    <label for="Cardio">Cardio (running, cycling, etc.)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Strengthtraining" name="exercise_preferences"
                      value="Strengthtraining"
                      {...register('exercise_preferences')} />
                    <label for="Strengthtraining">Strength training (weights, resistance exercises)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Pilates" name="exercise_preferences"
                      value="Pilates"
                      {...register('exercise_preferences')} />
                    <label for="Pilates">Yoga/Pilates</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="HighIntensity" name="exercise_preferences"
                      value="HighIntensity"
                      {...register('exercise_preferences')} />
                    <label for="HighIntensity">High-Intensity Interval Training  (HIIT)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Sports" name="exercise_preferences"
                      value="Sports"
                      {...register('exercise_preferences')} />
                    <label for="Sports">Sports (tennis, basketball, etc.)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="preference" name="exercise_preferences"
                      value="preference"
                      {...register('exercise_preferences')} />
                    <label for="preference">No preference</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Availability </label>
                  <div className='radio_box'>
                    <input type="radio" id="12daysperweek" name="availability"
                      value="12daysperweek"
                      defaultChecked
                      {...register('availability')} />
                    <label for="12daysperweek">1-2 days per week</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="23daysperweek" name="availability"
                      value="23daysperweek"
                      {...register('availability')} />
                    <label for="23daysperweek">3-4 days per week</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="56daysperweek" name="availability"
                      value="56daysperweek"
                      {...register('availability')} />
                    <label for="56daysperweek">5-6 days per week</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Everyday" name="availability"
                      value="Everyday"
                      {...register('availability')} />
                    <label for="Everyday">Every day</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Equipment Access </label>
                  <div className='radio_box'>
                    <input type="radio" id="Fullgymaccess" name="equipment_access"
                      value="Fullgymaccess"
                      defaultChecked
                      {...register('equipment_access')} />
                    <label for="Fullgymaccess">Full gym access</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Limitedhomeequipment" name="equipment_access"
                      value="Limitedhomeequipment"
                      {...register('equipment_access')} />
                    <label for="Limitedhomeequipment">Limited home equipment (dumbbells, bands, etc.)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Bodyweightexercisesonly" name="equipment_access"
                      value="Bodyweightexercisesonly"
                      {...register('equipment_access')} />
                    <label for="Bodyweightexercisesonly">Bodyweight exercises only</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Otherspecializedequipment" name="equipment_access"
                      value="Otherspecializedequipment"
                      {...register('equipment_access')} />
                    <label for="Otherspecializedequipment">Other specialized equipment (e.g., Pilates, reformer, rowing machine)</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Health Considerations  </label>
                  <div className='radio_box'>
                    <input type="radio" id="None" name="health_consideration"
                      value="None"
                      defaultChecked
                      {...register('health_consideration')} />
                    <label for="None">None</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="mobilityissues" name="health_consideration"
                      value="mobilityissues"
                      {...register('health_consideration')} />
                    <label for="mobilityissues">Joint or mobility issues</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Cardiovascularconditions" name="health_consideration"
                      value="Cardiovascularconditions"
                      {...register('health_consideration')} />
                    <label for="Cardiovascularconditions">Cardiovascular conditions</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Weightrelatedconcerns" name="health_consideration"
                      value="Weightrelatedconcerns"
                      {...register('health_consideration')} />
                    <label for="Weightrelatedconcerns">Weight-related concerns</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Chronichealthconditions" name="health_consideration"
                      value="Chronichealthconditions"
                      {...register('health_consideration')} />
                    <label for="Chronichealthconditions">Chronic health conditions (diabetes, hypertension, etc.)</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="InjuryRecovery" name="health_consideration"
                      value="InjuryRecovery"
                      {...register('health_consideration')} />
                    <label for="InjuryRecovery">Injury Recovery</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Age </label>
                  <input type="text" placeholder='Your answer'
                    name="age"
                    {...register('age')} />
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Gender  </label>
                  <input type="text" placeholder='Your answer'
                    name="gender"
                    {...register('gender')} />
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Nutritional Preferences  </label>
                  <div className='radio_box'>
                    <input type="radio" id="Nospecificdiet" name="nutritional_preferences"
                      value="Nospecificdiet"
                      defaultChecked
                      {...register('nutritional_preferences')} />
                    <label for="Nospecificdiet">No specific diet</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Vegetarian" name="nutritional_preferences"
                      value="Vegetarian"
                      {...register('nutritional_preferences')} />
                    <label for="Vegetarian">Vegan/Vegetarian</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Keto" name="nutritional_preferences"
                      value="Keto"
                      {...register('nutritional_preferences')} />
                    <label for="Keto">Keto/Low-carb</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Paleo" name="nutritional_preferences"
                      value="Paleo"
                      {...register('nutritional_preferences')} />
                    <label for="Paleo">Paleo</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Glutenfree" name="nutritional_preferences"
                      value="Glutenfree"
                      {...register('nutritional_preferences')} />
                    <label for="Glutenfree">Gluten-free</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Dairyfree" name="nutritional_preferences"
                      value="Dairyfree"
                      {...register('nutritional_preferences')} />
                    <label for="Dairyfree">Dairy-free</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Lifestyle Factors </label>
                  <div className='radio_box'>
                    <input type="radio" id="Deskjob" name="lifestyle_factors"
                      value="Deskjob"
                      defaultChecked
                      {...register('lifestyle_factors')} />
                    <label for="Deskjob">Desk job</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Activejob" name="lifestyle_factors"
                      value="Activejob"
                      {...register('lifestyle_factors')} />
                    <label for="Activejob">Active job</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="FrequentTravel" name="lifestyle_factors"
                      value="FrequentTravel"
                      {...register('lifestyle_factors')} />
                    <label for="FrequentTravel">Frequent Travel</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Irregularschedule" name="lifestyle_factors"
                      value="Irregularschedule"
                      {...register('lifestyle_factors')} />
                    <label for="Irregularschedule">Irregular schedule</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Familyresponsibilities" name="lifestyle_factors"
                      value="Familyresponsibilities"
                      {...register('lifestyle_factors')} />
                    <label for="Familyresponsibilities">Family responsibilities</label>
                  </div>
                  <div className='radio_box'>
                    <input type="radio" id="Other" name="lifestyle_factors"
                      value="Other"
                      {...register('lifestyle_factors')} />
                    <label for="Other">Other</label>
                  </div>
                </div>
                <div className='personalized_fitness_plan_area_box'>
                  <label className='section_label'>Please provide the email address where you'd like to receive your fitness plan </label>
                  <input type="text" placeholder='Your answer'
                    name="email"
                    {...register('email')} />
                </div>
                <div className='submit_btn'>
                  <input type="submit" value="Submit" />
                </div>
                {successMessage && (
                  <div>{successMessage}</div>
                )}
                {errorMessage && (
                  <div>{errorMessage}</div>
                )}
              </form>
              {isLoading && (
                <div className='text-center text-blue'> Your personalized fitness plan is being processed... <Loader /> </div>
              )}
            </div>
          </div>
        </div>
      </div>






      <div className="home-footer">
        <footer className="footerContainer home-footer1">
          <div className="home-container31">
            <span className="logo">FITEMPOWER</span>
            <nav className="home-nav1 bodySmall home-nav1">
              <span className="home-nav11 bodySmall">Home</span>
              <span className="home-nav22 bodySmall">About</span>
              <span className="home-nav32 bodySmall">Services</span>
              <span className="home-nav42 bodySmall">Testimonials</span>
              <span className="home-nav52 bodySmall">Contact</span>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container32">
            <span className="bodySmall home-text89">
              © 2023 myCompany, All Rights Reserved.
            </span>
            <div className="home-icon-group1">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon10">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon14">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default PersonalizedFitnessPlan