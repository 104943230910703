import React from 'react'

import { Helmet } from 'react-helmet'

import './page.css'

const Page = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Page - Spotless Hungry Crocodile</title>
        <meta property="og:title" content="Page - Spotless Hungry Crocodile" />
      </Helmet>
      <div className="page-banner">
        <h1 className="page-heading">Learn to keep it simple</h1>
        <span className="page-text">
          <span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
              volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in
              dignissim tortor.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </span>
        <div className="page-btn-group">
          <button className="page-button button">Get Started</button>
          <button className="page-button1 button">Learn More</button>
        </div>
      </div>
    </div>
  )
}

export default Page
