import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import NotFound1 from './views/not-found'
import Home from './views/home'
import Page from './views/page'
import PersonalizedFitnessPlan from './views/PersonalizedFitnessPlan'
import store from './stores/store'
import { Provider } from 'react-redux'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={NotFound1} exact path="/not-found" />
        <Route component={Home} exact path="/" />
        <Route component={Page} exact path="/page" />
        {/* <Route component={NotFound} path="**" /> */}
        <Route component={PersonalizedFitnessPlan} exact path="/personalized-fitness-plan" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<Provider store={store}>
  <Router>
    <App />
  </Router>
</Provider>, document.getElementById('app'))
